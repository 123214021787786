<template>
    <div>
        <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
            <div class="row align-items-center">
                <div class="col-sm-6 ">
                    <div class="form-group m-form__group row align-items-center">
                        <div class="col-12" style="display:flex;">
                            <div class="">
                                <dynamic-table-picker
                                    :table-key="tableKey"
                                    :allow-field-copying="true"
                                    :current-layout-id="currentLayoutId"
                                    @layout-changed="changeTableLayout"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6  m--align-right">
                    <template v-for="btn in buttons">
                        <button :class="btn.class" @click="btn.callback()">
                            <i v-if="btn.icon" :class="['fa', btn.icon]" />
                            {{ btn.label }}
                        </button>
                    </template>
                    <slot name="customControls" />
                </div>
            </div>
        </div>

        <ag-grid-vue
            :class="[isDark ? 'ag-theme-alpine-dark' : 'ag-theme-alpine', 'dynamic-table-ag-grid']"
            :column-defs="columns"
            dom-layout="autoHeight"
            auto-height="true"
            :row-height="rowHeight"
            row-model-type="serverSide"
            :pagination-page-size="recordsPerPage"
            server-side-store-type="partial"
            pagination="true"
            @grid-ready="onGridReady"
        />
    </div>
</template>

<style >
    @import url('../../../assets/vendors/bootstrap-select/dist/css/bootstrap-select.css');
    @import '../../../../node_modules/ag-grid-community/dist/styles/ag-grid.css';
    @import '../../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css';
    @import '../../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
    .dynamic-table-ag-grid {
        width: 100%;
    }
    .ag-grid-missing-image, .ag-grid-image-wrapper {
        height: 100px;
        width: 100px;
        border: 1px solid #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 3px;
    }
    .ag-grid-image-wrapper img {
        max-height: 100%;
        max-width: 100%;
    }
    .ag-grid-missing-image svg {
        color: #ccc;
    }
</style>
<script>
/* eslint vue/no-unused-components: 0 */
import Axios from 'axios'
import XcTableHelper from '../XcTableHelper'
import DynamicTablePicker from './DynamicTablePicker.vue'
import StrHelper from '../../../helpers/StrHelper'
import { AgGridVue } from "ag-grid-vue"
import 'ag-grid-enterprise'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import AmazonShipmentQuantityCell from "./AmazonShipmentQuantityCell.vue"
import AmazonShipmentHasAsinFilter from "@/components/Misc/DynamicTable/AmazonShipmentHasAsinFilter.vue"
import {AddToAmazonShipmentField} from "@/structures/AmazonShipmentFilterValues"

export default {
    components: {
        AmazonShipmentHasAsinFilter,
        AmazonShipmentQuantityCell,
        DynamicTablePicker,
        AgGridVue,
    },
    props: {
        buttons: {
            type: Array,
            default: () => [],
        },
        endpoint: {
            type: String,
            required: true,
        },
        sortSingleColumns: {
            type: Boolean,
            default: true,
        },
        recordsPerPage: {
            type: Number,
            default: XcTableHelper.DEFAULT_TAKE,
        },
        tableKey: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            AmazonShipmentQuantityAsinPicker: 'AmazonShipmentQuantityAsinPicker',
            search: '',
            XcTableHelper,
            currentLayout: [],
            currentLayoutId: 0,
            gridParams: null,
        }
    },
    setup() {
        const { skin } = useAppConfig()
        const isDark = computed(() => skin.value === 'dark')

        return {
            isDark,
        }
    },
    computed: {
        columns() {
            return this.currentLayout.map(field => {
                const hash = field.hash ? field.hash : StrHelper.generateHash(16)
                const type = XcTableHelper.dynamicTableFieldTypeToXcTableType(field.type)
                let cellRendererSelector
                let detailCellRenderer
                let cellRenderer
                let filter = XcTableHelper.getDynamicTableFieldFilterType(type)
                let filterParams = XcTableHelper.getDynamicTableFieldFilterOptions(type)
                let sortable = true
                let floatingFilter = true
                let menuTabs = ['generalMenuTab', 'filterMenuTab']
                if (type === 'imageUrl') {
                    cellRenderer = params => {
                        if (params.value) {
                            return `<div class="ag-grid-image-wrapper"><img src="${params.value}"  alt=""/></div>`
                        }
                        return `<div class="ag-grid-missing-image"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="1" y1="1" x2="23" y2="23"></line><path d="M21 21H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3m3-3h6l2 3h4a2 2 0 0 1 2 2v9.34m-7.72-2.06a4 4 0 1 1-5.56-5.56"></path></svg></div>`
                    }
                    filter = null
                    sortable = false
                    floatingFilter = false
                    menuTabs = ['generalMenuTab']
                    filterParams = null
                }

                if (type === XcTableHelper.TYPE_CUSTOM && field.key === AddToAmazonShipmentField.FIELD_NAME) {
                    cellRendererSelector = params => {
                        return {
                            component: 'AmazonShipmentQuantityCell',
                            params: {
                                item: params.value,
                            },
                        }
                    }
                    filter = 'AmazonShipmentHasAsinFilter'
                    sortable = false
                    floatingFilter = true
                    filterParams = null
                    detailCellRenderer = 'AmazonShipmentQuantityAsinPicker'
                }

                return {
                    type: type,
                    field: hash,
                    headerName: field.label,
                    key: field.key,
                    data: field.data,
                    customFieldId: field.customFieldId,
                    cellRenderer,
                    cellRendererSelector,
                    detailCellRenderer,
                    filter,
                    filterParams,
                    sortable,
                    resizable: false,
                    editable: false,
                    floatingFilter,
                    menuTabs,
                }
            })
        },
        rowHeight() {
            for (const column of this.columns) {
                if (column.type === 'imageUrl') {
                    return 110
                }
            }

            return 'auto'
        }
    },
    mounted() {
        this.emitInterface()
    },
    methods: {
        loadTable() {
            if (!this.gridParams) {
                return
            }
            for (const colIndex in this.columns) {
                const col = this.columns[colIndex]
                // set up sorts
                const sort = {
                    sorting: false,
                    sortDir: XcTableHelper.ORDER_BY_DESC,
                }

                if (col.sortByDefault) {
                    sort.sorting = true
                    sort.col = col.hash
                    sort.sortDir = col.sortByDefault === XcTableHelper.ORDER_BY_ASC ? XcTableHelper.ORDER_BY_ASC : XcTableHelper.ORDER_BY_DESC
                }

                if (!col.disableSort) {
                    this.columns[colIndex].sort = sort
                }
            }
            this.gridParams.api.setServerSideDatasource({getRows: dataFetchParams => this.loadData(dataFetchParams)})
        },
        onGridReady(params) {
            this.gridParams = params
        },
        loadData(params) {
            const data = {}
            data[XcTableHelper.ORDER_BY_PARAM] = this.getOrderBy(params.request.sortModel)
            data[XcTableHelper.FILTER_PARAM] = params.request.filterModel
            data[XcTableHelper.SEARCH_PARAM] = this.search
            data[XcTableHelper.PAGE_PARAM] = Math.ceil(params.request.endRow / this.recordsPerPage)
            data[XcTableHelper.TAKE_PARAM] = this.recordsPerPage
            data[XcTableHelper.REQUESTED_FIELDS_PARAM] = this.columns.map(field => ({
                type: field.type,
                valueKey: field.key,
                data: field.data ? JSON.parse(JSON.stringify(field.data)) : {},
                hash: field.field,
                customFieldId: field.customFieldId ? field.customFieldId : 0,
            }))

            Axios({ url: process.env.VUE_APP_API_HTTP_ROOT + this.endpoint, data, method: 'POST' })
                .then(resp => {
                    const data = resp.data.data.map(dataRow => {
                        let columns = {}
                        this.columns.forEach(column => {
                            const value = dataRow[column.field] || {}
                            columns[column.field] = value.custom || value.imageUrl || value.text || value.number
                        })
                        return columns
                    })
                    params.success({
                        rowData: data,
                        rowCount: resp.data.total,
                    })
                })
                .catch(() => {
                    params.fail()
                })
        },
        getOrderBy(sortModel) {
            let orderBy = []
            for (const model of sortModel) {
                orderBy.push({ col: model.colId, dir: model.sort })
            }

            if (orderBy.length === 0) {
                orderBy = [XcTableHelper.DEFAULT_ORDER_BY]
            }

            return orderBy
        },
        changeTableLayout(layout) {
            this.currentLayoutId = layout.id
            this.currentLayout = layout.layout
            this.loadTable()
        },
        isLink(link) {
            link += ''
            return link.indexOf('http://') === 0 || link.indexOf('https://') === 0
        },
        isImage(link) {
            link += ''
            return this.isLink(link) && (link.endsWith('.png') || link.endsWith('.jpg') || link.endsWith('.jpeg') || link.endsWith('.gif'))
        },
        emitInterface() {
            this.$emit("interface", {
                loadData: this.loadTable
            })
        }
    },

}
</script>
