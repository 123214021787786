<template>
    <div>
        <div v-if="loading">
            Loading...
        </div>
        <div v-else-if="listings.length === 0">
            No ASINs found
        </div>
        <div v-else>
            <b-form-input
                v-model="quantity"
                size="sm"
                type="number"
                :disabled="!hasDefaultListing"
                :placeholder="hasDefaultListing ? 'Quantity' : 'Select default listing'"
            />
            <b-button v-if="hasMultipleListings" variant="outline-primary" size="sm" @click="showListingsModal = true">
                Select default listing
            </b-button>
            <AmazonShipmentQuantityAsinPickerModal
                v-if="showListingsModal"
                :listings="listings"
                :default-listing-id="defaultListingId"
                :inventory-item-id="itemId"
                @close="showListingsModal = false"
                @updateDefaultListingId="setDefaultListingId"
            />
        </div>
    </div>
</template>

<style >

</style>
<script>

import AmazonShipmentQuantityAsinPickerModal from "./AmazonShipmentQuantityAsinPickerModal.vue"
import {BButton, BFormInput} from "bootstrap-vue"

export default {
    components: {
        AmazonShipmentQuantityAsinPickerModal,
        BButton,
        BFormInput,
    },
    data() {
        return {
            itemId: null,
            quantity: null,
            asins: [],
            listings: [],
            loading: false,
            showListingsModal: false,
            defaultListings: {},
        }
    },
    computed: {
        defaultListingId() {
            return this.defaultListings[this.currentMarketplaceId]?.listingId || 0
        },
        currentMarketplaceId() {
            return this.$store.getters.getCurrentAmazonMarketplaceId
        },
        hasMultipleListings() {
            return this.listings.length > 0
        },
        hasDefaultListing() {
            return this.defaultListingId > 0
        },
    },
    watch: {
        quantity(newQuantity, oldQuantity) {
            if (newQuantity === oldQuantity) {
                return
            }

            this.updateQuantity()
        },
    },
    created() {
        const {id, asinsMapping, listings, defaultAmazonListings} = this.params.item
        this.itemId = id
        this.asins = asinsMapping || []
        this.listings = listings || []
        this.defaultListings = defaultAmazonListings || {}
        const itemQuantity = this.$store.getters.getAmazonShipmentCreationStockQuantities[this.currentMarketplaceId]?.[this.itemId] || null
        if (itemQuantity) {
            this.quantity = itemQuantity.quantity
        }
    },
    methods: {
        updateQuantity() {
            this.$store.dispatch('setAmazonShipmentCreationStockQuantityForItem', {
                marketplaceId: this.currentMarketplaceId,
                itemId: this.itemId,
                quantity: parseInt(this.quantity),
                listingId: this.defaultListingId,
            })
        },
        setDefaultListingId(listing) {
            const newListing = {
                [this.currentMarketplaceId]: listing
            }
            this.defaultListings = {...this.defaultListings, ...newListing}
        }
    },
}
</script>
