<template>
    <div class="p-2">
        <b-form-group class="option-group">
            <b-form-radio v-model="selectedValue" name="asin-filter" :value="options.all">
                <span class="cursor-pointer">All</span>
            </b-form-radio>
            <b-form-radio v-model="selectedValue" name="asin-filter" :value="options.onlyWithAsins">
                <span class="cursor-pointer">Only items with ASINs</span>
            </b-form-radio>
            <b-form-radio v-model="selectedValue" name="asin-filter" :value="options.onlyWithoutAsins">
                <span class="cursor-pointer">Only items without ASINs</span>
            </b-form-radio>
        </b-form-group>
    </div>
</template>

<script>
import {
    BFormGroup,
    BFormRadio,
} from 'bootstrap-vue'
import {AddToAmazonShipmentField, AmazonShipmentFilterValues} from "@/structures/AmazonShipmentFilterValues"

export default {
    components: {BFormGroup, BFormRadio},
    data() {
        return {
            options: {
                all: AmazonShipmentFilterValues.ALL,
                onlyWithAsins: AmazonShipmentFilterValues.ONLY_WITH_ASINS,
                onlyWithoutAsins: AmazonShipmentFilterValues.ONLY_WITHOUT_ASINS,
            },
            selectedValue: AmazonShipmentFilterValues.ALL,
        }
    },
    computed: {},
    methods: {
        isFilterActive() {
            return this.selectedValue !== this.options.all
        },
        getModel() {
            return {
                filterType: AddToAmazonShipmentField.FILTER_NAME,
                type: 'equals',
                filter: this.selectedValue,
            }
        },

    },
    watch: {
        selectedValue: function () {
            this.params.api.onFilterChanged()
        }
    }
}
</script>
