<template>
    <b-modal
        title="Select default amazon listing for this item"
        size="lg"
        visible
        :ok-disabled="!canSave"
        :ok-title="loading ? 'Loading...' : 'Save'"
        @ok="saveDefaultListing"
        @close="$emit('close')"
        @hidden="$emit('close')"
    >
        <b-container v-if="selectedMarketplaceId" fluid>
            <b-row>
                <b-col cols="1" />
                <b-col cols="2">
                    <h5>ASIN</h5>
                </b-col>
                <b-col cols="4">
                    <h5>Quantity in marketplace</h5>
                </b-col>
                <b-col cols="5">
                    <h5>Amazon SKU</h5>
                </b-col>
            </b-row>
            <b-row v-for="(listing, index) of listings" :key="index" style="cursor:pointer;">
                <b-col cols="1">
                    <b-form-checkbox
                        v-model="selectedListingId"
                        :value="listing.id"
                    />
                </b-col>
                <b-col cols="2" @click="selectedListingId = listing.id">
                    {{ listing.asin }}
                </b-col>
                <b-col cols="4" @click="selectedListingId = listing.id">
                    {{ listing.quantity }}
                </b-col>
                <b-col cols="5" @click="selectedListingId = listing.id">
                    {{ listing.sku }}
                </b-col>
            </b-row>
        </b-container>
        <b-container v-else fluid>
            <b-row>
                <b-col
                    cols="12"
                >
                    <h3>No marketplace selected</h3>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<style>

</style>
<script>
import {
    BContainer,
    BCol,
    BRow,
    BFormCheckbox
} from 'bootstrap-vue'
import Axios from 'axios'

export default {
    components: {
        BContainer,
        BCol,
        BRow,
        BFormCheckbox,
    },
    props: {
        inventoryItemId: {
            type: String,
            required: true,
        },
        listings: {
            type: Array,
            required: true,
        },
        defaultListingId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            selectedListingId: 0,
            selectedMarketplaceId: '',
            loading: false,
        }
    },
    computed: {
        canSave() {
            return this.selectedListing && !this.loading
        },
        selectedListing() {
            return this.listings.find(listing => listing.id === this.selectedListingId)
        }
    },
    created() {
        this.selectedListingId = this.defaultListingId
        this.selectedMarketplaceId = this.$store.getters.getCurrentAmazonMarketplaceId
    },
    methods: {
        async saveDefaultListing(event) {
            event.preventDefault()
            this.loading = true
            await Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}amazon/save-default-inventory-item-listing`,
                data: {
                    inventoryItemId: this.inventoryItemId,
                    listingId: this.selectedListing.id,
                    asin: this.selectedListing.asin,
                    sku: this.selectedListing.sku,
                    marketplaceId: this.selectedMarketplaceId,
                },
                method: 'POST',
            })
            this.loading = false
            this.$emit('updateDefaultListingId', {
                listingId: this.selectedListing.id,
                asin: this.selectedListing.asin,
                sku: this.selectedListing.sku,
            })
            this.$emit('close')
        },
    },
}
</script>


